import * as React from 'react';
import Link from '../Link/Link';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/ir-logo.svg';

const useStyles = makeStyles(theme => ({
    header: {
        position: 'fixed',
        width: '100%',
        height: '75px',
        padding: '0 20px',
        backgroundColor: '#fff',
        zIndex: 100,
        boxShadow: '0 4px 12px 0 rgb(0 0 0 / 5%)',
    },
    headerInner: {
        maxWidth: '1000px',
        margin: '0 auto'
    },
    headerLinks: {
        float: 'right',
        margin: '0 -15px',
        [theme.breakpoints.down('md')]: {
            margin: '0 -12px',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0 -7px',
        },
        [theme.breakpoints.down(350)]: {
            margin: '0 -3px',
        }
    },
    headerLink: {
        position: 'relative',
        color: '#000',
        textDecoration: 'none',
        fontWeight: 'bold',
        fontSize: '14px',
        display: 'inline-block',
        justifyContent: 'center',
        lineHeight: '75px',
        height: '60px',
        textAlign: 'center',
        margin: '0 15px',
        boxSizing: 'border-box',
        "&.active-link": {
            borderBottom: '4px solid #13cabf',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '13px',
            margin: '0 12px',
            height: '58px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            margin: '0 7px',
            height: '56px',
        },
        [theme.breakpoints.down(350)]: {
            fontSize: '11px',
            margin: '0 3px',
            height: '54px',
        }
    },
    logo: {
        marginTop: '10px'
    }
  }));

export default props => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.header}>
                <div className={classes.headerInner}>
                    <Link href="/">
                        <Logo className={classes.logo} />
                    </Link>
                    <div className={classes.headerLinks}>
                        {props.links.map((link, index) => (
                            <Link className={ classes.headerLink } href={ link.url } partiallyActive={ link.url !== '/' ? true : false }>
                                { link.label }
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
