import { Builder } from '@builder.io/react';
import { Contact } from './Contact';

Builder.registerComponent(Contact, {
  name: 'Contact Form',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  // image:
  //   'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: "interestedInHeading",
      type: "text",
      defaultValue: "I'm interested in..."
    },
    {
      name: "interestedOptions",
      type: "list",
      subFields: [{ name: "option", type: "string" }],
    },
    {
      name: "formInputs",
      type: "list",
      subFields: [
        { name: "name", type: "string" },
        { name: "placeholder", type: "text" },
        { name: "type", type: "string", enum: ['text', 'email', 'phone'] }
      ],
    },
    {
      name: "submitButtonLabel",
      type: "text",
      defaultValue: "Send requests"
    }
  ]
});
