import * as React from 'react';
import Link from '../Link/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(them => ({
    footer: {
        padding: '20px 0',
        textAlign: 'center',
        backgroundColor: '#13cabf'
    },
    footerLink: {
        color: '#fff',
        textDecoration: 'none',
        fontWeight: 'bold',
        padding: '0 10px'
    }
  }));

export default props => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.footer}>
                {props.links.map((link) => (
                    <Link className={ classes.footerLink } href={ link.url }>
                    { link.label }
                    </Link>
                ))}
            </div>
        </>
    );
};
