import React from 'react';

import 'react-lazy-load-image-component/src/effects/opacity.css';

import Box from '@material-ui/core/Box';
import VisibilitySensor from 'react-visibility-sensor';


export const TextVisibilityUnderline = props => {
  const {
    transitionClassName,
    transitionDelay
  } = props;

  return (
    <VisibilitySensor partialVisibility={ true }>
      {({isVisible}) =>
        <Box className={ isVisible ? 'component-visible' : 'component-invisible' }>
          { props.children }
          <style>{`
            .${props.builderBlock.id} .${transitionClassName} { transition-delay: ${transitionDelay} }
          `}</style>
        </Box>
      }
    </VisibilitySensor>
  );
};
