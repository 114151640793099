import { Builder, withChildren } from '@builder.io/react';
import { TextVisibilityUnderline } from './TextVisibilityUnderline';

const TextVisibilityUnderlineWithChildren = withChildren(TextVisibilityUnderline);

Builder.registerComponent(TextVisibilityUnderlineWithChildren, {
  name: 'Visibility Underline Text',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  // image:
  //   'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'transitionClassName',
      type: 'string',
      defaultValue: 'pretty-underline'
    },
    {
      name: 'transitionDelay',
      type: 'string',
      defaultValue: '0ms'
    },
  ],
  defaultChildren: [
    { 
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'I am child text block!' } }
    }
  ]
});
