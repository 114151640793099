import { Builder } from '@builder.io/react';
import { ArticleListVertical } from './ArticleListVertical';

Builder.registerComponent(ArticleListVertical, {
  name: 'Blog Article Vertical List',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  // image:
  //   'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'quantity',
      type: 'number',
      defaultValue: 10
    }
  ]
});
