import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Grid from '@material-ui/core/Grid';
import Link from '../../Link/Link';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

export const ArticleList = props => {
  const {
    quantity
  } = props;

  const useStyles = makeStyles(theme => ({
    articleImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    articleImageWrapper: {
      width: '100%',
      height: '100%'
    },
    articleName: { 
      fontSize: '36px',
      marginTop: '-10px'
    },
    articleBlurb: {
      fontSize: '16px'
    }
  }));
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        {
          allBuilderModels {
            blogArticle {
              content
            }
          }
        }
      `}
      render={data => (
        <>
          <Grid container spacing={5} className="article-list-row">
            {
              data &&
              data.allBuilderModels &&
              data.allBuilderModels.blogArticle && 
              data.allBuilderModels.blogArticle.length ? ( data.allBuilderModels.blogArticle.slice(0, (quantity || 9)).map((article) => (
                <>
                  <Grid item xs={12} sm={4}>
                    <Link className="text-link" href={`/blog/${article.content.data.handle}`}>
                      <div className="article-list-image">
                        <Skeleton className='lazy-load-image-skeleton' variant="rectangular" />
                        <LazyLoadImage
                          src={article.content.data.image}
                          effect="opacity"
                          className={classes.articleImage}
                          wrapperClassName={classes.articleImageWrapper}
                        />
                      </div>
                      <div className="article-list-content">
                        <div className="article-name"> { article.content.name } </div>
                        <div className="content-text">{ article.content.data.blurb }</div>
                      </div>
                    </Link>
                  </Grid>
                </>
            ))) : <h4>No articles found.</h4>
          }
          </Grid>
        </>
      )}
    ></StaticQuery>
  );

};