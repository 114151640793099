import React from 'react';
import { Image } from '@builder.io/react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export const ProjectCard = props => {
  const {
    image,
    title
  } = props;

  return (
    <Box
      className="project-card"
      style={{ backgroundColor: '#ececec'}}
    >
      <Box
        style={{ height: '300px', position: 'relative' }}
      >
        <img src={image} />
      </Box>
      <Box
        className="project-content"
        style={{ backgroundColor: '#f7f7f7' }}
       >
        <h3 className="project-name">{title}</h3>
        <span className="project-link">View Project</span>
      </Box>
    </Box>
  );
};
