import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../components/Link/Link';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import '../builder-settings';

const useStyles = makeStyles(them => ({
  root: {
    backgroundColor: '#fff'
  },
  header: {},
  footer: {},
  content: {
    paddingTop: '75px' // Height of fixed header
  }
}));

const query = graphql`
  query {
    allBuilderModels {
      footer(limit: 1, options: { cachebust: true }) {
        content
      }
      navigation {
        data {
          links
        }
      }
    }
  }
`;

function PageLayout({ children }) {
  const classes = useStyles();
  return (
    <StaticQuery query={query}>
      {data => {
        const models = data.allBuilderModels;
        const navigation = models.navigation[0].data.links;
        const footer = models.footer[0].content;
        return (
          <div className={classes.root}>
            <Header links={ navigation } />
            <div className={classes.content}>{children}</div>
            <div className={classes.footer}>
              <BuilderComponent
                renderLink={Link}
                name="footer"
                content={footer}
              />
              <Footer links={ navigation } />
            </div>
          </div>
        );
      }}
    </StaticQuery>
  );
}

export default PageLayout;
